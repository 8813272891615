<template>
  <div>
    <!-- 触发预览的图片 -->
    <img :src="imageUrl" @click="showPreview" alt="点击预览图片" />

    <!-- 预览模态框 -->
    <transition name="modal">
      <div v-if="showModal" class="modal-mask"  >
        <div class="modal-wrapper" @click="closeOnMaskClick">
          <div class="modal-container">
            <div class="modal-header">
              <div @click="closePreview" style="text-align: right;  cursor: pointer;">
                <slot name="header">关闭预览</slot>
                <a-icon   type="close" />
              </div>
            </div>
            <div class="modal-body">
              <img :src="imageUrl" class="preview-image" alt="预览图片" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
    export default {
      props: {
        imageUrl: {
          type: String,
          required: true,
        },
      },
      data() {
        return {
          showModal: false,
        };
      },
      methods: {
        closeOnMaskClick(event) {
          debugger
          // 确保点击的是遮罩层而不是模态内容
          if (event.target === event.currentTarget) {

            this.closePreview();
          }
        },
        showPreview() {
          this.showModal = true;
        },
        closePreview() {
          this.showModal = false;
        },
      },
    };
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;text-align: right;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.preview-image {
  max-width: 100%;
  max-height: 800px;
  //height: auto;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  font-weight: bold;
}
</style>