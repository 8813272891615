import request from '@/utils/request'


// 查询宠物信息列表
export function listPet(query) {
  return request({
    url: '/biz/petInfo/list',
    method: 'get',
    params: query
  })
}

// 查询宠物信息分页
export function pagePet(query) {
  return request({
    url: '/biz/petInfo/page',
    method: 'get',
    params: query
  })
}

// 查询宠物信息详细
export function getPet(data) {
  return request({
    url: '/biz/petInfo/detail',
    method: 'get',
    params: data
  })
}

// 新增宠物信息
export function addPet(data) {
  return request({
    url: '/biz/petInfo/add',
    method: 'post',
    data: data
  })
}

// 修改宠物信息
export function updatePet(data) {
  return request({
    url: '/biz/petInfo/edit',
    method: 'post',
    data: data
  })
}

// 删除宠物信息
export function delPet(data) {
  return request({
    url: '/biz/petInfo/delete',
    method: 'post',
    data: data
  })
}
