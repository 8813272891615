<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="宠物名字" prop="petName" >
        <a-input v-model="form.petName" placeholder="请输入宠物名字" />
      </a-form-model-item>
      <a-form-model-item label="宠物头像" prop="petAvatar" :required="true">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange1"
        >
          <img v-if="form.petAvatar" :src="form.petAvatar" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="宠物图片" prop="petImage" :required="true">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange2"
        >
          <img v-if="form.petImage" :src="form.petImage" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="是否默认" prop="isDefault" >
        <a-radio-group v-model="form.isDefault">
          <a-radio-button value="1">是</a-radio-button>
          <a-radio-button value="0">否</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPet, addPet, updatePet } from '@/api/biz/petInfo'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      uploadLoading:false,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        petName: null,

        petImage: null,

        petAvatar: null,
        isDefault:null,
        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.petAvatar = info.file.originFileObj.ossUrl;
      }
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.petImage = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        petName: null,
        petImage: null,
        petAvatar: null, isDefault:null,
        createTime: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPet({"id":id}).then(response => {
        response.data.isDefault=response.data.isDefault+""
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePet(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPet(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
